import { Button } from '@mui/material'
import { useState } from 'react'
import { BackButton } from '../../components/BackButton'
import { BatchFinderGettingStarted } from './helpers/BatchFinderGettingStarted'
import { BatchFinderHowFiltersWork } from './helpers/BatchFinderHowFiltersWork'
import { BatchFinderSetupVideo } from './helpers/BatchFinderSetupVideo'
import { BatchFinderWhatIsIt } from './helpers/BatchFinderWhatIsIt'

export const BatchFinderGuide = ({
  global,
  currentLicense,
  maintenance,
  sessionId,
}) => {
  const [viewQuestion, setViewQuestion] = useState()

  const WHAT_IS = 1
  const GET_STARTED = 2
  const HOW_FILTERS = 3
  const SETUP_VIDEO = 4
  return (
    <div class="noselect">
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingTop: viewQuestion === SETUP_VIDEO ? '0px' : '20px',
        }}
      >
        {!viewQuestion && (
          <h1
            style={{
              paddingBottom: '20px',
            }}
          >
            BatchFinder Guide
          </h1>
        )}

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {(!viewQuestion || viewQuestion === WHAT_IS) && (
            <Button
              variant={viewQuestion === WHAT_IS ? 'contained' : 'outlined'}
              onClick={() => setViewQuestion(WHAT_IS)}
              sx={{ marginBottom: '10px' }}
            >
              What is BatchFinder?
            </Button>
          )}
          {/* {!viewQuestion && (
            <Button
              variant={viewQuestion === SETUP_VIDEO ? 'contained' : 'outlined'}
              onClick={() => setViewQuestion(SETUP_VIDEO)}
              sx={{
                marginBottom: '10px',
                animation: 'glow 1.8s infinite alternate',
              }}
            >
              Setup Video
            </Button>
          )} */}
          {(!viewQuestion || viewQuestion === GET_STARTED) && (
            <Button
              variant={viewQuestion === GET_STARTED ? 'contained' : 'outlined'}
              onClick={() => setViewQuestion(GET_STARTED)}
              sx={{ marginBottom: '10px' }}
            >
              How do I start?
            </Button>
          )}
          {(!viewQuestion || viewQuestion === HOW_FILTERS) && (
            <Button
              variant={viewQuestion === HOW_FILTERS ? 'contained' : 'outlined'}
              onClick={() => setViewQuestion(HOW_FILTERS)}
              sx={{ marginBottom: '10px' }}
            >
              How do filters work?
            </Button>
          )}
        </div>
        {viewQuestion === WHAT_IS && <BatchFinderWhatIsIt />}
        {viewQuestion === GET_STARTED && <BatchFinderGettingStarted />}
        {viewQuestion === HOW_FILTERS && <BatchFinderHowFiltersWork />}
        {viewQuestion === SETUP_VIDEO && <BatchFinderSetupVideo />}
        {!!viewQuestion && (
          <Button
            variant="contained"
            sx={{
              marginTop: '0px',
              position: viewQuestion === SETUP_VIDEO ? 'absolute' : 'static',
              top: '10px',
            }}
            onClick={() => {
              setViewQuestion()
            }}
          >
            Back
          </Button>
        )}
        {!viewQuestion && <BackButton />}
      </div>
    </div>
  )
}
