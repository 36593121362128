import { Box } from '@mui/material'
import { ContactInfoDialog } from '../../components/ContactInfoDialog'
import { AccountInfoDialog } from '../../components/AccountInfoDialog'
import { HomeRouteLink } from '../../components/HomeRouteLink'
import {
  AccountCircleRounded,
  ContactPageRounded,
  GroupAddRounded,
  KeyRounded,
  LoginRounded,
  OndemandVideoRounded,
} from '@mui/icons-material'
import { HomeDialogButton } from '../../components/HomeDialogButton'
import { LogOutButton } from '../../components/LogOutButton'
import { colors } from '../../colors'
import carrotIcon from '../../icons/carrotIcon.png'
import { useEffect, useState } from 'react'
import { Header } from './Header'
import { useNavigate, useSearchParams } from 'react-router-dom'

export const Home = ({
  sessionId,
  accountId,
  openCopySnackbar,
  userViews,
  isGettingUserViews,
  isLoggedIn,
  handleLogOut,
}) => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const [showContactInfo, setShowContactInfo] = useState(false)
  const [showAccountInfo, setShowAccountInfo] = useState(false)

  useEffect(() => {
    if (userViews?.finished_onboarding === false) {
      navigate({ pathname: '/onboarding', search: searchParams.toString() })
    }
  }, [userViews])

  return (
    <div class="noselect">
      <Header />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: '15px',
        }}
      >
        <ContactInfoDialog
          visible={showContactInfo}
          hideContactInfo={() => setShowContactInfo(false)}
        />
        <AccountInfoDialog
          visible={showAccountInfo}
          hideAccountInfo={() => setShowAccountInfo(false)}
          accountId={accountId}
          openCopySnackbar={openCopySnackbar}
        />
        <div
          style={{
            marginTop: '10vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {!isLoggedIn && (
            <HomeRouteLink
              label="Log In"
              subLabel="Or Create Account"
              to="/login"
              icon={
                <LoginRounded
                  color={colors.black}
                  sx={{
                    marginRight: 'auto',
                    fontSize: 28,
                  }}
                />
              }
              glow={true}
            />
          )}
          {isLoggedIn && (
            <HomeDialogButton
              onClick={() => setShowAccountInfo(true)}
              label="Account"
              icon={
                <AccountCircleRounded
                  color={colors.black}
                  sx={{ marginRight: 'auto', fontSize: 28 }}
                />
              }
            />
          )}
          {/* {((!isGettingUserViews && !userViews) ||
            (userViews && !userViews.setup_guide_video)) && (
            <HomeRouteLink
              label="Setup Guide & Proof"
              to="/batchfinder/guide/video"
              icon={
                <OndemandVideoRounded
                  color={colors.black}
                  sx={{ marginRight: 'auto', fontSize: 28 }}
                />
              }
              glow={true}
            />
          )} */}
          <HomeRouteLink
            label="BatchFinder"
            to="/batchfinder"
            icon={
              <img
                src={carrotIcon}
                alt="carrot"
                style={{
                  height: '30px',
                  marginRight: 'auto',
                }}
              />
            }
          />
          {!!sessionId && (
            <HomeRouteLink
              label="License Access"
              to="/license"
              icon={
                <KeyRounded
                  color={colors.black}
                  sx={{
                    marginRight: 'auto',
                    fontSize: 28,
                  }}
                />
              }
            />
          )}
          {!!sessionId && (
            <HomeRouteLink
              label="Earn Free Licenses"
              subLabel="Referral System"
              to="/referral"
              icon={
                <GroupAddRounded
                  color={colors.black}
                  sx={{
                    marginRight: 'auto',
                    fontSize: 28,
                  }}
                />
              }
            />
          )}
          <HomeDialogButton
            onClick={() => setShowContactInfo(true)}
            label="Contact"
            icon={
              <ContactPageRounded sx={{ marginRight: 'auto', fontSize: 28 }} />
            }
          />
          <LogOutButton handleLogOut={handleLogOut} sessionId={sessionId} />
        </div>
      </div>
    </div>
  )
}
