import { Card, Dialog } from 'ui-neumorphism'
import { colors } from '../../../colors'
import { Button } from '@mui/material'

export const BatchFinderSelfieDialog = ({
  visible,
  selfieExternalUrl,
  _closeSelfieDialog,
}) => {
  return (
    <Dialog visible={visible}>
      <Card
        style={{
          justifyContent: 'center',
          padding: '10px 0px',
          width: '90vw',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin: '0px 20px',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <p
              style={{
                fontSize: '22px',
                color: colors.black,
                paddingBottom: '15px',
                fontWeight: 500,
              }}
            >
              What's this?
            </p>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <p>
                Unfortunately, Instacart's security requires a selfie
                verification. After clicking the button below, you will be
                redirected to a secure page where you will take a selfie and
                verify your identity. After completing the verification, please
                return to BatchFinder.
              </p>
              {!!selfieExternalUrl && (
                <Button
                  variant="contained"
                  onClick={() => {
                    console.log(selfieExternalUrl)
                    window.open(selfieExternalUrl, '_blank')
                    _closeSelfieDialog()
                  }}
                  style={{ marginTop: '10px' }}
                >
                  Go to Verification
                </Button>
              )}
              {!selfieExternalUrl && (
                <>
                  <p>No verification link found. Please contact support.</p>
                  <Button
                    variant="contained"
                    onClick={() => {
                      _closeSelfieDialog()
                    }}
                    style={{ marginTop: '10px' }}
                  >
                    Close
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      </Card>
    </Dialog>
  )
}
