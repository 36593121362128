import { BatchFinderFocusBatch } from '../helpers/BatchFinderFocusBatch'
import { FakeLocationSelector } from '../service-helpers/FakeLocationSelector'
import { BackButton } from '../../../components/BackButton'
import { Button } from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { getBatchFinderServiceSettings } from '../../../hooks/getBatchFinderServiceSettings'
import { StopTurbo } from './components/StopTurbo'
import { TurboRunning } from './components/TurboRunning'
import { TurboStopped } from './components/TurboStopped'
import { updateBatchFinderServiceLocation } from '../../../hooks/updateBatchFinderServiceLocation'
import { getStoreLocations } from '../../../hooks/instacart/getStoreLocations'
import { pingAlive } from '../../../hooks/instacart/pingAlive'
import { getActiveBatch } from '../../../hooks/instacart/getActiveBatch'
import { BatchFinderSelfieDialog } from '../helpers/BatchFinderSelfieDialog'
export const BatchFinderTurbo = ({
  global,
  currentLicense,
  maintenance,
  sessionId,
  accountId,
}) => {
  const {
    saveSessionId,
    handleLogOut,
    showAlert,
    handleGetCurrentLicense,
    setIsLoading,
  } = global
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [batches, _batches] = useState([])
  const [focusBatch, _focusBatch] = useState()
  const [storeLocations, setStoreLocations] = useState()
  const [lastGetStoresTime, _lastGetStoresTime] = useState(0)
  const [lastGetLocationTime, _lastGetLocationTime] = useState(0)
  const [lastGetActiveBatchTime, setLastGetActiveBatchTime] = useState(0)
  const [lastGetServiceSettingsTime, _lastGetServiceSettingsTime] = useState(0)
  const [lastRealLocationUpdateTime, _lastRealLocationUpdateTime] = useState(0)
  const [hasRetrievedServiceSettings, _hasRetrievedServiceSettings] =
    useState(false)

  const [turboRunning, _turboRunning] = useState(false)
  const [showBackgrounding, _showBackgrounding] = useState(false)

  const [locationLatitude, _locationLatitude] = useState()
  const [locationLongitude, _locationLongitude] = useState()
  const [locationName, _locationName] = useState()
  const [serviceStopReason, _serviceStopReason] = useState()

  const [locationSelectorVisible, _locationSelectorVisible] = useState(false)
  const [realLatitude, _realLatitude] = useState()
  const [realLongitude, _realLongitude] = useState()
  const [locationIsReal, _locationIsReal] = useState()

  const [showSelfieDialog, _showSelfieDialog] = useState(false)
  const [selfieExternalUrl, _selfieExternalUrl] = useState()

  const handleGetServiceSettings = async (starting_turbo) => {
    const res = await getBatchFinderServiceSettings(sessionId)
    if (res.statusCode === 400) {
      if (res.body === 'Invalid session') {
        navigate({ pathname: '/app', search: searchParams.toString() })
        handleLogOut()
      } else {
        showAlert(
          'Error getting settings',
          `Raw Error: ${JSON.stringify(res)}`,
          'client_error',
        )
      }
    } else if (res.statusCode === 200) {
      const s = res.body.step_settings.instacart
      if (locationIsReal !== s.location_settings?.is_real_location)
        _locationIsReal(s.location_settings?.is_real_location)
      if (locationName !== s.location_settings?.display_name)
        _locationName(s.location_settings?.display_name)
      if (locationLatitude !== s.location_settings?.latitude)
        _locationLatitude(s.location_settings?.latitude)
      if (locationLongitude !== s.location_settings?.longitude)
        _locationLongitude(s.location_settings?.longitude)
      if (serviceStopReason !== s.stop_reason) _serviceStopReason(s.stop_reason)

      _batches(s.batches)

      if (!s.step_function_id && turboRunning) {
        _turboRunning(false)
        _showBackgrounding(false)
        if (s.stop_reason === 'Accepted batch') {
          navigate({
            pathname: '/batchfinder/active',
            search: searchParams.toString(),
          })
        }
      } else if (s.step_function_id && !turboRunning && !starting_turbo) {
        if (s.step_function_name === 'BatchFinder') {
          navigate({
            pathname: '/batchfinder/service',
            search: searchParams.toString(),
          })
        } else {
          _showBackgrounding(true)
        }
      }

      if (!hasRetrievedServiceSettings) _hasRetrievedServiceSettings(true)
    } else if (res.statusCode === 0) {
    } else {
      showAlert(
        'Error getting settings',
        `Raw Error: ${JSON.stringify(res)}`,
        'client_error',
      )
    }
  }

  const handleSaveLocation = async (
    latitude,
    longitude,
    isRealLocation,
    locationName,
  ) => {
    const res = await updateBatchFinderServiceLocation(
      sessionId,
      latitude,
      longitude,
      isRealLocation,
      locationName,
    )
    if (res.statusCode === 400) {
      if (res.body === 'Invalid session') {
        navigate({ pathname: '/app', search: searchParams.toString() })
        handleLogOut()
      } else {
        showAlert(
          'Error saving location',
          `Raw Error: ${JSON.stringify(res)}`,
          'client_error',
        )
      }
    } else if (res.statusCode === 200) {
      handleGetServiceSettings()
    } else if (res.statusCode === 0) {
      // No-op
    } else {
      showAlert(
        'Error saving location',
        `Raw Error: ${JSON.stringify(res)}`,
        'client_error',
      )
    }
  }

  const handleGetActiveBatch = async () => {
    const res = await getActiveBatch(sessionId)
    if (res.statusCode === 400) {
      if (res.body === 'Invalid session') {
        navigate({ pathname: '/app', search: searchParams.toString() })
        handleLogOut()
      } else if (res.body === 'Invalid Instacart credentials') {
        navigate({ pathname: '/batchfinder', search: searchParams.toString() })
      } else if (res.body === 'Rate limited') {
        setLastGetActiveBatchTime(lastGetActiveBatchTime + 60000) //60 second pause
      }
    } else if (res.statusCode === 200) {
      if (res.body.batch) {
        navigate({
          pathname: '/batchfinder/active',
          search: searchParams.toString(),
        })
      }
    }
  }

  const handleGetStoreLocations = async (latitude, longitude) => {
    await pingAlive(sessionId) // Keep session alive
    const res = await getStoreLocations(sessionId, latitude, longitude, 5)
    if (res.statusCode === 400) {
      if (res.body === 'Invalid session') {
        navigate({ pathname: '/app', search: searchParams.toString() })
        handleLogOut()
      } else if (res.body === 'License not found') {
        await handleGetCurrentLicense()
        navigate({ pathname: '/app', search: searchParams.toString() })
      } else if (res.body === 'Invalid Instacart credentials') {
        navigate({ pathname: '/batchfinder', search: searchParams.toString() })
      }
    } else if (res.statusCode === 200) {
      setStoreLocations(res.body.stores)
    }
  }

  const handleUserLocationError = () => {
    alert(
      'Location not enabled.\n\nHow to fix:\n1. Open the Settings app\n2. Search up "Location Services"\n3. Scroll to “Safari” and click it\n4. Set it to "While Using the App"\n5. Restart BatchFinder\n\nIf that doesn\'t work:\n1. Open the Settings app > Safari.\n2. Click "Clear History and Website Data".\n3. Restart BatchFinder\n4. Click "Allow" when asked for location.\n\nYour location is required so BatchFinder can access batches in your area.',
    )
    navigate({ pathname: '/app', search: searchParams.toString() })
  }

  // Update the location of the user
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (new Date().getTime() > lastGetLocationTime + 30000) {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              if (!position?.coords?.latitude || !position?.coords?.longitude) {
                handleUserLocationError()
              } else {
                _realLatitude(position.coords.latitude)
                _realLongitude(position.coords.longitude)
                _lastGetLocationTime(new Date().getTime())
              }
            },
            (error) => handleUserLocationError(),
          )
        } else {
          handleUserLocationError()
        }
      }
    }, 1000)
    return () => clearInterval(intervalId)
  })

  // Save users real location
  useEffect(() => {
    const intervalId = setInterval(async () => {
      if (
        sessionId &&
        locationIsReal &&
        realLatitude &&
        realLongitude &&
        new Date().getTime() > lastRealLocationUpdateTime + 60000
      ) {
        _lastRealLocationUpdateTime(new Date().getTime())
        handleSaveLocation(realLatitude, realLongitude, true, 'n/a')
      }
    }, 10000)
    return () => clearInterval(intervalId)
  })

  // Get store locations
  useEffect(() => {
    const intervalId = setInterval(async () => {
      if (
        sessionId &&
        new Date().getTime() > lastGetStoresTime + 60000 &&
        hasRetrievedServiceSettings &&
        realLatitude &&
        realLongitude &&
        !turboRunning
      ) {
        _lastGetStoresTime(new Date().getTime())

        handleGetStoreLocations(realLatitude, realLongitude)
      }
    }, 1000)
    return () => clearInterval(intervalId)
  })

  // Check for active batch
  useEffect(() => {
    const intervalId = setInterval(async () => {
      if (sessionId && new Date().getTime() > lastGetActiveBatchTime + 120000) {
        setLastGetActiveBatchTime(new Date().getTime())
        handleGetActiveBatch()
      }
    }, 2000)
    return () => clearInterval(intervalId)
  })

  useEffect(() => {
    const intervalId = setInterval(async () => {
      const pollingRate = turboRunning ? 1000 : 30000
      if (
        sessionId &&
        new Date().getTime() > lastGetServiceSettingsTime + pollingRate
      ) {
        _lastGetServiceSettingsTime(new Date().getTime())
        handleGetServiceSettings()
      }
    }, 500)
    return () => clearInterval(intervalId)
  })

  return (
    <div class="noselect">
      <BatchFinderSelfieDialog
        visible={showSelfieDialog}
        selfieExternalUrl={selfieExternalUrl}
        _closeSelfieDialog={() => _showSelfieDialog(false)}
      />
      <BatchFinderFocusBatch
        batch={focusBatch}
        setBatch={_focusBatch}
        sessionId={sessionId}
        showAlert={showAlert}
      />
      <FakeLocationSelector
        visible={locationSelectorVisible}
        hideFakeGPS={() => _locationSelectorVisible(false)}
        storeLocations={storeLocations}
        realLatitude={realLatitude}
        realLongitude={realLongitude}
        handleSaveLocation={handleSaveLocation}
        usingRealLocation={locationIsReal}
        currentSettingLatitude={locationLatitude}
        currentSettingLongitude={locationLongitude}
        currentSettingLocationName={locationName}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <h1
          style={{
            paddingTop: '20px',
          }}
        >
          BatchFinder Turbo
        </h1>
        <TurboStopped
          global={global}
          sessionId={sessionId}
          accountId={accountId}
          currentLicense={currentLicense}
          turboRunning={turboRunning}
          _turboRunning={_turboRunning}
          _locationSelectorVisible={_locationSelectorVisible}
          locationIsReal={locationIsReal}
          locationName={locationName}
          locationLatitude={locationLatitude}
          locationLongitude={locationLongitude}
          realLatitude={realLatitude}
          realLongitude={realLongitude}
          storeLocations={storeLocations}
          hasRetrievedServiceSettings={hasRetrievedServiceSettings}
          serviceStopReason={serviceStopReason}
          handleGetServiceSettings={handleGetServiceSettings}
          _showBackgrounding={_showBackgrounding}
          showBackgrounding={showBackgrounding}
        />
        <TurboRunning
          turboRunning={turboRunning}
          batches={batches}
          _focusBatch={_focusBatch}
        />
      </div>
      <StopTurbo
        global={global}
        sessionId={sessionId}
        turboRunning={turboRunning}
        _turboRunning={_turboRunning}
        handleGetServiceSettings={handleGetServiceSettings}
      />

      <Button
        variant="contained"
        sx={{ position: 'absolute', bottom: '20px', left: '20px' }}
        onClick={() => {
          if (turboRunning) {
            _turboRunning(false)
            _showBackgrounding(true)
          } else {
            navigate({
              pathname: '/batchfinder',
              search: searchParams.toString(),
            })
          }
        }}
      >
        Back
      </Button>
    </div>
  )
}
